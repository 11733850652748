@import './section';

section {
  > .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 32px 0;

    @media (max-width: 769px) {
      margin: 0 20px;
    }
  }

  .section-header {
    margin-bottom: 22px;

    h1 {
      font-size: 26px;
      font-weight: $font-weight-light;
      margin: 0 0 0 8px;
    }

    a, .ui-link {
      font-size: 16px;
      margin-bottom: 2px;
      &:hover {
        color: $link-hover;
      }
    }
  }
}
