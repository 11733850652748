.highlight-seeds .trigger-item__wrapper, .highlight-seed.trigger-item__wrapper {
  $image-size: 184px;
  $item-width: 224px;

  .ui-item {
    &:before {
      content: '';
      position: absolute;
      width: $item-width;
      height: $item-width;
      background-color: #dadada;
      z-index: -1;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    &__bookmark {
      right: 28px;
      top: 28px;
    }

    &__image-container {
      width: $image-size;
      height: $image-size;
      overflow: hidden;
      margin: (($item-width - $image-size) / 2) auto;
      border-radius: 4px;
      box-shadow: 0 -1px 30px 0 rgba(0, 0, 0, 0.2), 0 2px 3px 0 rgba(0, 0, 0, 0.1);

      img {
        display: block;
        width: $image-size;
        height: $image-size;
      }

      .quantity-2,
      .quantity-3,
      .quantity-4,
      .quantity-more {
        overflow: hidden;

        .col-image:first-child {
          border-right: 1px solid #dedede;
        }

        .col-image {
          width: 50%;
          float: left;
          height: $image-size;
          position: relative;
          overflow: hidden;

          img {
            width: $image-size;
            height: $image-size;
            margin: 0 auto;
            position: absolute;
            left: -50%;
          }
        }
      }

      .quantity-3 {
        .col-image:last-child {
          img:first-child {
            border-bottom: 1px solid #dedede;
          }

          img {
            width: 100%;
            height: auto;
            position: static;
          }
        }
      }

      .quantity-4,
      .quantity-more {
        .col-image {
          img:first-child {
            border-bottom: 1px solid #dedede;
          }

          img {
            width: $image-size / 2;
            height: $image-size / 2;
            position: static;
          }
        }
      }

      .quantity-more {
        .col-image .quantity {
          background-color: rgba(255, 255, 255, 0.8);
          width: $image-size / 2;
          height: $image-size / 2;
          position: absolute;
          top: $image-size / 2;
          font-size: 32px;
          font-weight: $font-weight-semibold;
          text-align: center;
          color: $andes-blue-400;
          line-height: $image-size / 2;
        }
      }
    }

    &__content {
      border-top-color: transparent;
    }

    &.seed {
      display: flex;
      &:hover {
        height: 314px;
      }
      .ui-item {
        &__image-container {
          flex: 0 0 auto;
          margin-bottom: 0;
        }

        &__content {
          display: flex;
          flex: 1 0 auto;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 18px;
          padding-top: 18px;
        }

        &__title {
          margin: 3px 0 0;
          min-height: 0;
          max-height: 14px * 2 * 1.3;
          opacity: 1;
          text-align: center;
          visibility: visible;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  h2 {
    color: #333333;
    font-size: 24px;
    font-weight: $font-weight-semibold;
    line-height: 0.92;
    margin: 22px 0 0;
    text-align: center;
  }
}
