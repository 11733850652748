@import 'common';

.slider--small {
  .slide__layer,
  .slide__image {
    height: $slide-small-desktop-xl;
  }
}

.slider--medium {
  .slide__layer,
  .slide__image {
    height: $slide-medium-desktop-xl;
  }
}

.slider--large {
  .slide__layer,
  .slide__image {
    height: $slide-large-desktop-xl;
  }
}

.slide__text {
  padding: (6 * $mdu) (9 * $mdu);
}

.slide__heading {
  font-size: $font-size-18;
}

.slide__title {
  font-size: $font-size-36;
  letter-spacing: 1px;
  line-height: $paragraph-line-height;
  max-width: $title-subtitle-max-width;
}

.slide__subtitle {
  font-size: $font-size-24;
  letter-spacing: $letter-spacing-small;
  line-height: $paragraph-line-height;
  max-width: $title-subtitle-max-width;
}

@media (max-width: 1919px) {
  .slider--small {
    .slide__layer,
    .slide__image {
      height: $slide-small-desktop;
    }
  }

  .slider--medium {
    .slide__layer,
    .slide__image {
      height: $slide-medium-desktop;
    }
  }

  .slider--large {
    .slide__layer,
    .slide__image {
      height: $slide-large-desktop;
    }
  }
}
