$home-z-index: (
  header-editable: 2000,
  over-header: 110,
  header: 100,
  below-header: 50,
  payment-over-banner: 40,
  over-footer: 10,
  editable: 10,
);

@mixin z-index($key) {
  z-index: map-get($home-z-index, $key);
}
