@import 'commons';

.contact-form {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  margin: 48px auto 32px;
  padding: 64px;
  width: 784px;
}

.contact-form__input--email {
  width: 384px;
}

.andes-spinner__mask {
  border-radius: 4px;
}
