@import 'variables';

.slider {
  width: 100%;

  .carousel-container {
    position: relative;
  }

  .slick-track {
    height: 100%;
  }

  .slick-dots {
    bottom: 30px;
    height: 0;
    left: 0;
    right: 0;
    width: auto;

    li {
      margin: 0;
      text-align: center;

      button {
        background-color: #c6c6c6;
        border-radius: 50%;
        display: inline;
        height: 6px;
        padding: 1px;
        transition: none $transition-duration $transition-timing-function;
        transition-property: background-color, height, width;
        width: 6px;

        &:before {
          content: '';
        }
      }

      &.slick-active button {
        background-color: $andes-white;
        height: 10px;
        width: 10px;
      }
    }
  }

  .slick-active button,
  .slick-dots li button {
    background-clip: padding-box;
    border: solid 1px rgba(0, 0, 0, 0.2);
  }

  .prev-button,
  .next-button {
    box-sizing: border-box;
    height: $arrow-height;
    margin-top: -40px;
    top: 50%;
    width: $arrow-width;
  }

  .prev-button {
    border-radius: 0 4px 4px 0;
    left: 0;
  }
  .next-button {
    border-radius: 4px 0 0 4px;
    right: 0;
  }
}
