$mdu: 8px;

$font-size-16: 16px;
$font-size-36: 36px;
$font-size-40: 40px;
$font-size-48: 48px;

$rrss-icons-scale: scale(1.3);

// as defined in Andes
$standard-line-height: 1;
$paragraph-line-height: 1.25;

$small-header-height: 8 * $mdu;
$large-header-height: 11.25 * $mdu;

$small-header-height-tools: 8 * $mdu;
$large-header-height-tools: 17.25 * $mdu; // 138px

$small-header-height-fashion: 8 * $mdu;
$large-header-height-fashion: 11.25 * $mdu; // 90px
$large-header-height-fashion-solid: 15 * $mdu; // 120px

$flash-component-height: 3.75 * $mdu;
$flash-component-link-font-size-mobile: 10px;

$flash-component-height-tools: 3.75 * $mdu; // 30px
$flash-component-height-fashion: 3.75 * $mdu; // 30px

// this value is the result of adding half of the paymentMethods component height
// to the height of the desktop banner, and substracting the paymentMethods padding top
$payment-methods-top-with-banner: 198px;
$payment-methods-button-with-banner: 274px;
