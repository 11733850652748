@import "core";

.price-and-image__title{
  font-size: $font-size-16;
  text-align: center;
}

.price-and-image__container {
  padding: (6 * $mdu) 0  (7 * $mdu) 0 !important;
}
