@import 'core';

.column-discovery {
  margin: auto;
  max-width: 148 * $mdu;
  padding-top: 4 * $mdu;
}

.column-discovery__title {
  font-size: $font-size-16;
  margin-bottom: 3 * $mdu;
}

.column-discovery__columns {
  column-count: 2;
  column-gap: 3 * $mdu;
  width: 100%;
}
