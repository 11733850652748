@import "../../../styles/variables";

.lgpd-landing__title-section {
  margin: 0 auto;
  text-align: center;

	.title-section__title {
	  font-size: $font-size-24;
	  font-weight: $font-weight-semibold;
	}

  .title-section__subtitle {
    font-size: $font-size-16;
    margin-top: $mdu;
  }

  .title-section__text {
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    line-height: $paragraph-line-height;
  }
}
