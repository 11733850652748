@import '../ui-item/styles/item.scss';

@mixin price-digits($fontSize) {
  .price-tag.ui-item__price {
    font-size: $fontSize;
  }
  .price-tag__disabled {
    font-size: 14px;
  }
}

@mixin item-size($imageSize, $cardHeight) {
  margin: 0;
  width: $imageSize;
  height: $cardHeight;
  img, .ui-item__image-container {
    width: $imageSize;
    height: $imageSize;
  }

  &__wrapper {
    margin: 0 8px 16px;
    width: $imageSize;
    height: $cardHeight;
  }
}

@mixin item-container-setup() {
  /* Set the max-height of the item to the container, in order to avoid repainting during the transition */
  height: $item-max-height+$item-shadow;

  /* Negative margin bottom to compensate the offset of the item max-height */
  margin-bottom: ($item-min-height)-(($item-max-height+$item-shadow));
}

.price-tag.ui-item__price {
  .price-tag-symbol, .price-tag-fraction {
    height: 24px;
    display: inline-flex;
    align-items: flex-end
  }
}

.ui-item {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  display: inline-block;
  vertical-align: text-top;

  &__bookmark {
    outline: none;
  }

  &__title {
    margin-bottom: 0;
    margin-top: 6px;
    min-height: auto;
  }

  &__title, &__discount-price.price-tag {
    opacity: 0;
  }

  &__discount-price.price-tag {
    top: 0;
  }

  .price-tag__disabled {
    position: absolute;
  }

  &.price-text &__price {
    font-size: 20px;
  }

  .price-tag-cents {
    display: none;
  }

  &.price-digits-5 {
    @include price-digits(23px);
  }
  &.price-digits-6 {
    @include price-digits(20px);
  }
  &.price-digits-7 {
    @include price-digits(18px);
    .ui-item__discount-text {
      font-size: 12px;
    }
  }
  &.price-digits-8 {
    @include price-digits(17px);
    .ui-item__discount-text {
      font-size: 12px;
    }
  }
  &.price-digits-9, &.price-digits-10, &.price-digits-11 {
    @include price-digits(16px);
    .ui-item__discount-text {
      font-size: 11px;
    }
  }
}

.low-end {
  a.ui-item, a.ui-item:hover {
    .ui-item__bookmark {
      display: none;
    }
  }
}
