// General
$white-54: rgba(255, 255, 255, 0.54);

// Home
$light-gray: #ddd;
$gray: #999;
$dark-gray: #666;
$light-black: #333;
$home-background: #EBEBEB;
$timeline: #dcdcdc;

$font-small: 14px;
$section-separator: 15px;

// Create a space of 2px between footer and site-shopping-info
$small-home-padding: 64px 0 2px 0;
$home-padding: 96px 0 2px 0;

// Links
$link-hover: #1259C3;

// Item
$item-info-color: rgb(51, 51, 51);
$item-info-background: rgba(255, 255, 255, 0.7);
$item-min-height: 314px;
$item-max-height: 360px;
$item-height-mobile: 230px;
$font-size-mid: 20px;
$item-shadow: 32px;
$item-width: 224px;

// Transitions
$transition-duration: .1s;
$transition-timing-function: ease-out;
$transition-visibility-delay: 0.1s;

// Carousel
$image-border-radius: 4px;
$sub-title: 20px;

$secondary-button: #e3eefc;
$secondary-button-hover: #d9e8fc;
