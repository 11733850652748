@import 'commons';

.contact-info {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  display: table;
  margin: auto;
  width: 98 * $mdu;
}

.contact-info__col-data {
  display: table-cell;
  height: 100%;
  padding: 8 * $mdu;
  width: 50%;
}

.contact-info__col-data--full {
  width: 100%;
}

/* Map styles */
.ui-map-contain--fit {
  display: table-cell;
  height: 100%;

  & .ui-map__full { /* Override plugin styles */
    border-radius: 0 4px 4px 0 !important;
    box-shadow: none !important;
  }
}
