.ui-item__bookmark {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 16px;
  right: 16px;
  border: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .7);
  border-radius: 50%;
  padding: 0;
  display: none;

  &:hover &-icon {
    fill: #1259c3;
  }

  &-icon {
    fill: #3483fa;
    width: 18px;
    top: 11px;
    position: absolute;
    left: 9px;
  }

  /**
   * Only show button on Item "over" state, and if it's not bookmarked
   */
  .ui-item:hover &,
  &.ui-item__bookmark--added {
    display: block;
  }
}
