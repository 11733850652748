$slide-small-desktop-xl: 340px;
$slide-medium-desktop-xl: 578px;
$slide-large-desktop-xl: 720px;

$slide-small-desktop: 256px;
$slide-medium-desktop: 384px;
$slide-large-desktop: 520px;

$slide-small-mobile-xl: 340px;
$slide-medium-mobile-xl: 540px;
$slide-large-mobile-xl: 640px;

$slide-small-mobile: 256px;
$slide-medium-mobile: 384px;
$slide-large-mobile: 520px;

$layer-color: rgba(0, 0, 0, 0.1);

$slide-max-width: 1920px;

$letter-spacing-small: 0.4px;

$title-subtitle-max-width: 149 * $mdu;
