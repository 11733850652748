.buyer-info {
  background-color: $andes-white;
  overflow: hidden;
  
  .container {
    display: flex;
    justify-content: center;
    margin-bottom: ($mdu * 3);
  }
  
  .buyer-info__card {
    align-items: center;
    border-radius: (0.75 * $mdu);
    border: solid 1px $andes-gray-100;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 ($mdu * 2.5);
    padding: ($mdu * 2);
  }
  
  p {
    color: $andes-gray-450;
    font-size: $font-size-14;
    line-height: 1.2;
    margin: ($mdu / 2 ) 0 ($mdu / 4 ) 0;
  }

  a {
    cursor: pointer;
    font-size: $font-size-14;
  }
}
