.price-and-image {
  background-color: $light-black;
  display: flex;
  flex-direction: column;
  user-select: none;

  &.dark {
    background-color: $light-black;

    .price-and-image__title {
      color: $andes-white;
    }
  }

  &.light {
    background-color: $andes-white;

    .price-and-image__title {
      color: $light-black;
    }
  }
}

.price-and-image__row {
  display: flex;
  justify-content: center;
  margin-left: $mdu;
  width: 100%;
}

.price-and-image__title {
  text-transform: uppercase;
  width: 100%;
}
