@import "./core";

.how-buy-landing__steps {
  display: flex;
  justify-content: center;
  padding: ($mdu * 11.5) 0 ($mdu * 6.25) 0;

  .steps__separator {
    background: rgba($black, 0.2);
    height: $mdu * 9.5;
    position: relative;
    top: $mdu * 17;
    width: 1px;

    &:first-child {
      display: none;
    }
  }

  .steps__step {
    box-sizing: border-box;
    margin: 0 ($mdu * 5);
    width: $mdu * 38;
  }

  .step__title {
    padding: ($mdu * 3) 0 ($mdu * 1.5) 0;
  }
}
