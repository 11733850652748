@import './item';
@import './item-animation';

.ui-item {
  @include item-size($item-width, $item-min-height);

  &__installments,
  &__attributes {
    margin-top: 1px;
  }

  &__price-block {
    margin-top: -2px;
  }

  &.price-digits-6 {
    @include price-digits(24px);
    .ui-item__discount-text {
      vertical-align: 2px;
    }
  }
  &.price-digits-7 {
    @include price-digits(22px);
    .ui-item__discount-text {
      font-size: 14px;
    }
  }

  &.price-digits-6,
  &.price-digits-7 {
    .ui-item {
      &__content {
        padding: 24px 10px 14px 24px;
      }
    }
  }

  &.price-digits-7,
  &.price-digits-8 {
    .price-tag-symbol,
    .price-tag-fraction,
    .price-tag-decimal-separator,
    .price-tag-cents {
      padding-bottom: 2px;
    }
  }
}
