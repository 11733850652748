@import '~@andes/form/index';
@import '~@andes/textfield/index';
@import '~@andes/button/index';
@import '~@andes/snackbar/index';
@import '~frontend-recaptcha/styles/recaptcha';

.contact-form {
  background-color: $andes-white;
  box-sizing: border-box;
  position: relative;
}

.contact-form__title {
  color: $light-black;
  font-size: $font-size-24;
  font-weight: $font-weight-semibold;
  margin-bottom: 3 * $mdu;
}

.contact-form__input {
  margin-top: 8 * $mdu;
}

.hidden {
  display: none;
}

.contact-form__spinner {
  @include z-index(below-header);
}

.contact-form__recaptcha-validation {
  color: #ff5a5f;
  font-size: $font-size-14;
  position: relative;
  top: -40px;
}

//Override recaptcha styles
.recaptcha {
  margin: (4 * $mdu) 0 (6 * $mdu);
}
