.recommendations {
  .slick-list {
    @include item-container-setup();
    white-space: nowrap;
  }

  .ui-item {
    white-space: normal;
  }

  .carousel-container {
    .prev-button, .next-button {
      box-sizing: border-box;
      top: 120px;
    }
  }

  @import './seeds/desktop';
}

.mshops-recommendations-wrapper {
  height: 100%;

  .ui-item__wrapper {
    height: 100%;

    a {
      height: calc(100% - #{3 * $mdu});
    }
  }

  .ui-item:hover {
    &.with-installments,
    &.with-discount {
      height: calc(100% - #{3 * $mdu});
    }

    .price-tag {
      top: 14px;
    }

    .ui-item__price-block {
      margin-top: $mdu;
    }
  }

  .ui-item:hover.with-installments,
  .ui-item:hover.with-discount {
    height: calc(100% - #{3 * $mdu});
  }

  .ui-item__title {
    opacity: 1;
    visibility: visible;
  }

  .slick-list {
    height: 100%;
    margin-bottom: 0;
  }

  .slick-track {
    height: (49 * $mdu);
  }

  .price-tag {
    opacity: 1;
    top: 14px;
    visibility: visible;
  }

  .ui-item__price-block {
    margin-top: $mdu;
  }

  .price-tag-cents {
    display: block;
  }
}
