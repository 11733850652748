.site-shopping-info {
  overflow: hidden;
  background-color: #ffffff;
  margin-top: 20px;

  .info-slide {
    box-sizing: border-box;
    padding: 35px 45px 20px 45px;
    text-align: center;
    position: relative;

    > .img-container {
      height: 55px;
      img {
        vertical-align: middle;
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
      color: #4b4b4b;
      margin: 16px 0 0 0;
    }

    p {
      color: #999999;
      font-size: 15px;
      margin: 4px 0 10px 0;
      line-height: 1.2;
    }

    a {
      color: #3483fa;
      font-size: 14px;
      text-decoration: none;
    }
  }

  .info-slide__disclaimer:after {
    content: "*";
  }

  .info-slide__shipping-conditions:before {
    content: "*";
    padding-left: ($mdu/2);
    padding-right: ($mdu/2);
  }
}
