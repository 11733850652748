.ui-item {
  &__price-block {
    overflow: hidden;
  }
  &__price {
    color: #333;
    font-size: 24px;
    line-height: 1.2;
  }

  &__discount {
    &-price {
      font-size: 14px;
      line-height: 1.5;
      color: #999;
      position: absolute;
      top: 8px;
      left: 25px;
    }

    &-text {
      font-size: 14px;
      vertical-align: 3px;
      padding-left: 10px;
      color: #39b54a;
    }
  }

  &__installments, &__attributes {
    display: block;
    font-size: 14px;
    color: #333;
    margin-top: 5px;
  }

  &__installments:after, &__attributes:after {
    content: '\200b'; // takes up height even when empty
  }
}
