.column-discovery__title {
  font-size: $font-size-16;
  font-weight: $font-weight-semibold;
  letter-spacing: 1.5px;
  margin-bottom: 3 * $mdu;
  text-transform: uppercase;
}

.column-discovery__wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}
