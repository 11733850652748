@import 'variables';

.image-hidden {
  display: none;
}

.slide {
  margin: 0 auto;
  width: 100vw;

  .slick-slider .slick-slide {
    width: 100%;
  }
}

.slide,
.slide__layer {
  max-width: $slide-max-width;
}

.slide--full-width {
  width: 100%;
}

.slide__layer {
  background-color: $layer-color;
  height: 100%;
  position: absolute;
  width: 100%;
}

.slide__image {
  align-items: center;
  background-color: transparent;
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: flex;
  width: inherit;
}

.slide__text {
  position: relative;
  margin: auto;

  &.slide__text--center {
    text-align: center;

    .slide__heading {
      margin: auto;
    }
  }

  &.slide__text--left {
    margin-left: 0;
    text-align: left;
  }

  &.slide__text--right {
    margin-right: 0;
    text-align: right;
  }

  .slide__heading {
    color: $andes-white;
    letter-spacing: 2.5px;

    & + .slide__title {
      margin-top: (1.5 * $mdu);
    }
  }

  .slide__title {
    color: $andes-white;
    overflow: hidden;

    & + .slide__subtitle {
      margin-top: (1.5 * $mdu);
    }
  }

  .slide__subtitle {
    color: $andes-white;
    overflow: hidden;
  }
}

.image-adjust-height {
  background-size: auto 100%;
}

.image-adjust-width {
  background-size: 100% auto;
}
