@import '~loki-price/styles/Price';
@import './item-bookmark';
@import './item-price';
@import './item-shipping';
@import './item-title';
@import './item-review';
@import './item-states';
@import './item-image';

.ui-item {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  color: #333;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1), 0 -1px 2px 0 rgba(0, 0, 0, .1);
  display: inline-flex;
  position: relative;
  text-decoration: none;
  flex-direction: column;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0 8px 16px;
  z-index: 1;

  &__content {
    position: relative;
    border-top: 1px solid rgba(51, 51, 51, .1);
    padding: 24px 24px 14px;
  }
}
