@import './site-shopping-info';

.site-shopping-info {
  .container {
    width: 1200px;
  }

  .info-slide {
    float: left;
    width: 100 / 3 * 1%;

    & + .info-slide:before {
      background-color: #e2e2e2;
      content: '';
      display: block;
      margin-top: -65 / 2 * 1px;
      height: 65px;
      left: 0;
      position: absolute;
      top: 150px;
      width: 1px;
    }
  }

  a:hover {
    color: #1259C3;
  }

  @media screen and (max-width: 769px) {
    display: flex;
    margin-top: 60px;
    width: 100vw;
  }
}
