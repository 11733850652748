@import 'core';

.label-image {
  margin-bottom: 3 * $mdu;
  max-width: 72.5 * $mdu;
}

.label-image--small {
  height: 29 * $mdu;
}

.label-image--large {
  height: 61 * $mdu;
}

.label-image__text {
  font-size: $font-size-24;
  height: 11.5 * $mdu;
  letter-spacing: 0.5 * $mdu;
  padding: 0 (6 * $mdu);
}

.label-image--behavior-hover {
  .label-image__text {
    height: 0;
    transition: height 0.3s ease-out, visibility 0.2s ease-out;
    visibility: hidden;
  }

  &:hover .label-image__text {
    height: 11.5 * $mdu;
    transition: height 0.3s ease-in-out, visibility 0.4s ease-in-out 0.1s;
    visibility: visible;
  }
}
