.ui-item__shipping {
  width: 36px;
  height: 36px;
  top: -18px;
  right: 16px;
  border: solid 1px rgba(51, 51, 51, .1);
  background-color: #fff;
  border-radius: 50%;
  position: absolute;

  &-icon {
    fill: #666;
    width: 20px;
    left: 8px;
    top: 9px;
    position: absolute;
  }

  &-free &-icon {
    fill: #39b54a;
  }

  &-label {
    display:none;
  }
}
