@import "./core";

.how-buy-landing__title-section {
  max-width: $max-width;
  padding-top: ($mdu * 6);

  .title-section__text {
    margin-top: ($mdu * 5);

    strong {
      padding: 0 ($mdu * 0.5);
    }
  }
}
