@import "../../../styles/variables";

.how-buy-landing__more-info-message {
  background: $andes-gray-70;
  padding: ($mdu * 3) 0;
  text-align: center;

  p {
    margin: 0 auto;
  }

  .more-info-message__link {
    color: $andes-blue-400;
    cursor: pointer;
    padding-left: (0.5 * $mdu);
  }
}
