@import "../../../styles/variables";

.how-buy-landing__title-section {
  margin: 0 auto;
  text-align: center;

  .title-section__text strong {
    padding: 0 ($mdu * 0.5);
  }

  .title-section__title {
    font-size: ($mdu * 3);
  }
}
