.section-header {
  display: flex;
  line-height: 1.2;
  align-items: center;

  h1 {
    color: #666666;
  }

  a,
  .ui-link {
    align-self: baseline;
    color: $andes-blue-400;
    font-size: 16px;
    margin: auto 0 2px 0;
    text-decoration: none;
  }

  .ui-link {
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    font-family: inherit;
    padding: 0;
  }

  .ui-link-button {
    outline: 0;
  }

  h1 + a,
  h1 + .ui-link {
    margin-left: 16px;
  }
}
