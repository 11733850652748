& {
  min-height: 0;
  margin: 2 * $mdu 0;
}

.container {
  width: 100%;
}

.payment-data {

  &-container {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    height: 90px;
    margin: 0 auto;
    max-width: 148 * $mdu;
    overflow: hidden;
  }

  &-group {
    display: flex;
    flex-wrap: wrap;
    &-normal,
    &-promo {
      flex: 1 1 auto;
    }
  }

  &-section {
    flex: 1 0 auto;
    font-size: 18px;
    padding: 20px;
    white-space: nowrap;
    height: 90px;

    &-promo {
      flex: 0 1 auto;
      &-logo {
        display: flex;
        padding: 0;
      }
    }
  }

  &-title {
    display: inline-block;
    margin-top: 1px;
  }

  &-issuer {
    margin: auto;
  }

  &-icon {
    float: left;
    margin-right: 16px;
    width: 48px;
    height: 54px;
  }

  &-subtitle {
    color: #999999;
    font-size: 14px;
    margin-top: -1px;
    a,
    .ui-link {
      color: $andes-blue-400;
      text-decoration: none;
      &:hover {
        color: $link-hover;
      }
    }
  }
}
