.grid-container {
  align-content: stretch;
  box-sizing: border-box;
  display: inline-flex;
  position: relative;
  width: 100%;

  .ui-item__wrapper {
    flex-grow: 1;
    height: auto;
    max-width: 73 * $mdu;
    width: 100%;

    a {
      height: 100%;
      width: 100%;
    }
  }

  .ui-item:hover {
    height: 100%;

    &.with-installments,
    &.with-discount {
      height: 100%;
    }
  }

  .ui-item__image-container {
    width: 100%;

    img {
      margin: auto;
    }
  }

  .ui-item__title {
    opacity: 1;
    visibility: visible;
  }

  .price-tag {
    opacity: 1;
    top: 14px;
    visibility: visible;
  }

  .with-discount .ui-item__price-block {
    margin-top: 8px;
  }

  .ui-item:hover.with-discount {
    height: 100% !important;
  }
};
