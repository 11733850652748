@import '../../../styles/variables';

.legal-notices__title-section {
  margin: 0 auto;
  max-width: $legal-notices-view-max-width;
  padding: ($mdu * 12) 0 ($mdu * 8.75);
  text-align: left;

	.title-section__title {
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
	}
}
