@import 'home';

@import '~@andes/modal/base';
@import '~@andes/modal/lib/styles/shared/header';
@import '~@andes/modal/lib/styles/shared/scroll';
@import '~@andes/modal/lib/styles/loose/index';
@import '~@andes/modal/lib/styles/loose/header';
@import '~@andes/modal/lib/styles/loose/scroll';

@import '../../../components/item/desktop';
@import '../../../components/payment-data/desktop';
@import '../../../components/recommendations/desktop';
@import '../../../components/card-with-image-price/styles/desktop';
@import '../../../components/carousel-with-tabs/desktop';
@import '../../../components/price-and-image/styles/desktop';
@import '../../../components/section/desktop';
@import '../../../components/site-shopping-info/desktop';
@import '../../../components/slide/styles/desktop';
@import '../../../components/slider/styles/common';
@import '../../../components/grid-recommendations/desktop';
@import '../../../components/labelImage/styles/desktop';

@import '../../../appearance/Discovery/styles/desktop';
@import '../../../appearance//BuyerInfo/styles/desktop';

// Contact Page
@import '../../../components/contact-form/styles/desktop';
@import '../../../components/contact-info/styles/desktop';

// How Buy Landing
@import '../../../appearance/HowBuyInfo/styles/desktop';

// LGPD Landing
@import '../../../appearance/DataPrivacyInfo/styles/desktop';

// Legal Notices Landing
@import '../../../appearance/LegalNoticesInfo/styles/desktop';

// Adhesion contracts Landing
@import '../../../appearance/AdhesionContractInfo/styles/desktop';

.home {
  .row {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .hub_carousel {
    max-width: 1250px;

    .carousel-container {
      max-width: 95%;
    }

    .arrow-disabled {
      cursor: default;
    }
  }

  &.home--tools,
  &.home--electronics {
    padding-top: $large-header-height-tools;

    &.home--with-flash {
      padding-top: $large-header-height-tools + $flash-component-height-tools;
    }
  }

  &.home--fashion,
  &.home--home_deco,
  &.home--sports,
  &.home--toys {
    padding-top: $large-header-height-fashion;

    &.home--with-flash {
      padding-top: $large-header-height-fashion-solid;
    }

    &.home--with-transparency {
      padding-top: 0;

      &.home--with-flash {
        padding-top: $flash-component-height-fashion;
      }
    }
  }

  &.home--with-banner {
    padding-top: $andes-spacing-64;
  }
}

.home--with-banner .payment-data {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: $payment-methods-top-with-banner;
  width: 100%;
}

/* Modifiers for flash component and banner components */
.nav-header__banner-image {
  background-size: 100% auto;
  height: 200px;
  width: 100%;
}

.wrapper--with-banner+main>.home {
  padding-top: 0;

  &.home--with-payment {
    padding-top: $andes-spacing-40;
  }
}

.home--with-flash {
  padding-top: $large-header-height + $flash-component-height;
}

/* Minor styles fixes for the site shopping info */
.site-shopping-info .info-slide {
  padding-top: $andes-spacing-64;
  padding-bottom: $andes-spacing-64;
}

@media (max-width: 768px) {
  .nav-header__banner {
    background-color: $light-gray;
    display: block;
    line-height: 0;
    padding-top: $small-header-height;
    width: 100%;
  }

  .nav-header__banner-image {
    background-size: 100% auto;
    height: 96px;
    width: 100%;
  }

  .home--with-flash {
    padding-top: $small-header-height + $flash-component-height;
  }

  .wrapper--with-banner+main>.home {
    padding-top: 0;
  }

  .home--with-payment {
    padding-top: 0 !important;
  }

  .payment-data {
    position: relative !important;
    top: 0 !important;
  }
}

.modal {
  max-width: 840px;
  max-height: 88%;

  iframe {
    width: 100%;
    border: 0;
    -webkit-overflow-scrolling: touch;
  }
}
