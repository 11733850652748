@import '~ui-library_ml/src/styles/helpers/variables';
@import '~ui-library_ml/src/styles/helpers/mixins';
@import '~ui-library_ml/src/styles/base/reset';

@import '~ui-library_ml/src/components/button/styles/button';
@import '~ui-library_ml/src/components/card/styles/card';
@import '~ui-library_ml/src/components/loading/styles/loading';

@import '~ui-library_ml/src/components/carousel/styles/paged';
@import '~ui-library_ml/src/components/carousel/styles/arrows';

@import '~@andes/spinner/base';
@import '~@andes/spinner/lib/styles/modifier/block';
@import '~@andes/spinner/lib/styles/modifier/fullscreen';
@import '~@andes/spinner/lib/styles/size/large';

// For splinter
@import '~splinter-ui/lib/reader';
@import '~splinter-ui/lib/all/index';
@import '../../../styles/mesh';
// ***************************

@import '../../../styles/mixins';
@import '../../../styles/common';
@import '../../../styles/variables';
@import '../../../styles/utilities';

@import 'variables';

// Common imports
@import '../../../components/item/item';

// Use defaults box-sizing instead of Andes Normalize
*,
*:before,
*:after {
  box-sizing: content-box;
}

body {
  font-family: $font-family-primary;
}

main[role=main] {
  font-weight: $font-weight-regular;
  -webkit-font-smoothing: antialiased;
}

.home {
  padding: $home-padding;
  overflow-x: hidden;

  /* flexbox grid*/
  .row {
    padding-bottom: 0;
    position: relative;
    h3 {
      margin-bottom: $section-separator;
      margin-top: $section-separator;
    }
    .title {
      color: #9c9c9c;
      font-size: 26px;
      font-weight: 200;
      margin-left: 6px;
      margin-top: 0;
      margin-bottom: 0;
    }
    &.center {
      justify-content: center;
    }
  }

  @media screen and (max-width: 767px) {
    .ui-modal {
      &-bg {
        background-color: rgba(0, 0, 0, 0.6);
      }

      &-dialog__button-close {
        &:after,
        &:before {
          background-color: $andes-white;
        }
      }

      &-dialog,
      &-bg {
        opacity: 1;
        transition: opacity $transition-duration $transition-timing-function;
        display: table;
        &__content-wrapper {
          height: 100%;
        }
        &--hidden {
          opacity: 0;
          pointer-events: none;
          iframe {
            display: none;
          }
          .ui-modal-dialog {
            &__content-box,
            &__button-close {
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

.home--with-padding-bottom {
  padding-bottom: 8 * $mdu;
}

.nav-footer-contact {
  margin-top: 1px;
}

// Override tools footer margin needed in vip, search and cart
.nav-tools-footer {
  margin-top: 0 !important;
}
